// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-devops-en-tsx": () => import("./../src/pages/devops.en.tsx" /* webpackChunkName: "component---src-pages-devops-en-tsx" */),
  "component---src-pages-dotnet-developer-en-tsx": () => import("./../src/pages/dotnet-developer.en.tsx" /* webpackChunkName: "component---src-pages-dotnet-developer-en-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-pl-tsx": () => import("./../src/pages/index.pl.tsx" /* webpackChunkName: "component---src-pages-index-pl-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-trainee-en-tsx": () => import("./../src/pages/trainee.en.tsx" /* webpackChunkName: "component---src-pages-trainee-en-tsx" */)
}

